/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import e from "../../core/Accessor.js";
import { isSome as i } from "../../core/arrayUtils.js";
import s from "../../core/Collection.js";
import { IdentifiableMixin as r } from "../../core/Identifiable.js";
import { watch as l, initial as o } from "../../core/reactiveUtils.js";
import { property as n } from "../../core/accessorSupport/decorators/property.js";
import { cast as a } from "../../core/accessorSupport/decorators/cast.js";
import "../../core/has.js";
import { subclass as h } from "../../core/accessorSupport/decorators/subclass.js";
import { get as d } from "../../core/accessorSupport/get.js";
import p from "../../support/actions/ActionBase.js";
import c from "../../support/actions/ActionButton.js";
import u from "../../support/actions/ActionSlider.js";
import y from "../../support/actions/ActionToggle.js";
import b from "./ListItemPanel.js";
import { findLayerVisibilityMode as m, findLayerListMode as v, canDisplayLayer as f, getNormalizedChildLayerProperty as C } from "./support/layerListUtils.js";
var g;
const w = s.ofType({
    key: "type",
    defaultKeyValue: "button",
    base: p,
    typeMap: {
      button: c,
      toggle: y,
      slider: u
    }
  }),
  S = s.ofType(w),
  _ = "layer",
  M = "child-list-mode",
  P = "hide";
let O = g = class extends r(e) {
  constructor(t) {
    super(t), this.actionsSections = new S(), this.actionsOpen = !1, this.checkPublishStatusEnabled = !1, this.children = new (s.ofType(g))(), this.childrenSortable = !0, this.hidden = !1, this.layer = null, this.listItemCreatedFunction = null, this.listModeDisabled = !1, this.open = !1, this.panel = null, this.parent = null, this.view = null;
  }
  initialize() {
    if (this.addHandles([l(() => [this.layer, this.layer?.listMode, this.listModeDisabled], () => this._watchLayerProperties(this.layer), o), l(() => this.checkPublishStatusEnabled, t => this._updateChildrenPublishing(t), o), l(() => this.view, t => this._updateChildrenView(t), o), l(() => this.panel, t => this._setListItemOnPanel(t), o)]), "function" == typeof this.listItemCreatedFunction) {
      const t = {
        item: this
      };
      this.listItemCreatedFunction.call(null, t);
    }
  }
  destroy() {
    this.panel?.destroy(), this.children.destroyAll(), this.view = null;
  }
  get connectionStatus() {
    const {
      layerView: t,
      publishing: e
    } = this;
    if (!e && t && "connectionStatus" in t) return t.connectionStatus;
  }
  get error() {
    return this.layer?.loadError;
  }
  get incompatible() {
    const {
      layerView: t
    } = this;
    return !(!t || !("spatialReferenceSupported" in t)) && !t.spatialReferenceSupported;
  }
  get layerView() {
    const {
      layer: t,
      view: e
    } = this;
    if (!t || !e || "sublayer" === t.type) return null;
    const i = "subtype-sublayer" === t.type ? t.parent : t;
    return e.allLayerViews.find(t => t.layer === i) ?? null;
  }
  castPanel(t) {
    return this.panel?.open && !t.hasOwnProperty("open") && (t.open = !0), t ? new b(t) : null;
  }
  get sortable() {
    return "knowledge-graph-sublayer" !== this.layer?.type && this._get("sortable");
  }
  set sortable(t) {
    this._set("sortable", t);
  }
  get title() {
    const t = d(this, "layer.layer");
    return (!t || t && d(this, "layer.layer.loaded")) && d(this, "layer.title") || d(this, "layer.attributes.title") || "";
  }
  set title(t) {
    this._override("title", t);
  }
  get publishing() {
    const {
      layer: t,
      checkPublishStatusEnabled: e
    } = this;
    return e && t && "publishingInfo" in t && "publishing" === t.publishingInfo?.status;
  }
  get updating() {
    const {
      layerView: t,
      connectionStatus: e,
      layer: i,
      publishing: s
    } = this;
    return !s && !e && (t ? t.updating : "loading" === i?.loadStatus || !1);
  }
  get visible() {
    return this.layer?.visible;
  }
  set visible(t) {
    const e = this.layer;
    e && (e.visible = t);
  }
  get visibleAtCurrentScale() {
    return this.layerView?.visibleAtCurrentScale ?? !0;
  }
  get visibleAtCurrentTimeExtent() {
    return this.layerView?.visibleAtCurrentTimeExtent ?? !0;
  }
  get visibilityMode() {
    return m(this.layer);
  }
  clone() {
    return new g({
      actionsSections: this.actionsSections.clone(),
      actionsOpen: this.actionsOpen,
      checkPublishStatusEnabled: this.checkPublishStatusEnabled,
      children: this.children.clone(),
      childrenSortable: this.childrenSortable,
      hidden: this.hidden,
      layer: this.layer,
      listItemCreatedFunction: this.listItemCreatedFunction,
      listModeDisabled: this.listModeDisabled,
      open: this.open,
      panel: this.panel,
      parent: this.parent,
      sortable: this.sortable,
      title: this.title,
      view: this.view,
      visible: this.visible
    });
  }
  _setListItemOnPanel(t) {
    t && (t.listItem = this);
  }
  _updateChildrenPublishing(t) {
    const e = this.children;
    e && e.forEach(e => e.checkPublishStatusEnabled = t);
  }
  _updateChildrenView(t) {
    const e = this.children;
    e && e.forEach(e => e.view = t);
  }
  _createChildren(t) {
    const {
        listModeDisabled: e,
        children: i
      } = this,
      s = t.filter(t => !i.some(e => e.layer === t));
    i.addMany(this._createChildItems(s, e));
  }
  _destroyChildren(t) {
    const {
        children: e
      } = this,
      i = e.filter(e => !t.includes(e.layer));
    e.destroyMany(i);
  }
  _sortChildren(t) {
    this.children.sort((e, i) => t.indexOf(i.layer) - t.indexOf(e.layer));
  }
  _destroyAllChildren() {
    this.removeHandles(M), this.children.destroyAll();
  }
  _watchChildLayerListMode(t) {
    this.removeHandles(M), this.listModeDisabled || this.addHandles(t.toArray().map(e => l(() => e.listMode, () => this._compileChildren(t))), M);
  }
  _compileChildren(t) {
    const e = this.listModeDisabled ? t : t?.filter(t => v(t) !== P);
    e?.length ? (this._createChildren(e), this._destroyChildren(e), this._sortChildren(e), this._watchChildLayerListMode(t)) : this._destroyAllChildren();
  }
  _watchSublayerChanges(t) {
    t && this.addHandles(t.on("change", () => this._compileChildren(t)), _);
  }
  _initializeChildLayers(t) {
    this._compileChildren(t), this._watchSublayerChanges(t);
  }
  _createChildItems(t, e) {
    return t.map(t => e || f(t) ? new g({
      layer: t,
      checkPublishStatusEnabled: this.checkPublishStatusEnabled,
      listItemCreatedFunction: this.listItemCreatedFunction,
      listModeDisabled: this.listModeDisabled,
      parent: this,
      view: this.view
    }) : null).filter(i).reverse();
  }
  _watchLayerProperties(t) {
    if (this.removeHandles(_), this.removeHandles(M), !t) return;
    if ("hide-children" === (!this.listModeDisabled && v(t))) return void this.children.destroyAll();
    const e = C(t);
    e && this.addHandles(l(() => t[e], i => {
      t.hasOwnProperty(e) && this._initializeChildLayers(i);
    }, o), _);
  }
};
t([n({
  type: S
})], O.prototype, "actionsSections", void 0), t([n()], O.prototype, "actionsOpen", void 0), t([n()], O.prototype, "checkPublishStatusEnabled", void 0), t([n({
  type: s
})], O.prototype, "children", void 0), t([n()], O.prototype, "childrenSortable", void 0), t([n({
  readOnly: !0
})], O.prototype, "connectionStatus", null), t([n({
  readOnly: !0
})], O.prototype, "error", null), t([n()], O.prototype, "hidden", void 0), t([n({
  readOnly: !0
})], O.prototype, "incompatible", null), t([n()], O.prototype, "layer", void 0), t([n({
  readOnly: !0
})], O.prototype, "layerView", null), t([n()], O.prototype, "listItemCreatedFunction", void 0), t([n({
  nonNullable: !0
})], O.prototype, "listModeDisabled", void 0), t([n()], O.prototype, "open", void 0), t([n({
  type: b
})], O.prototype, "panel", void 0), t([a("panel")], O.prototype, "castPanel", null), t([n()], O.prototype, "parent", void 0), t([n({
  value: !0
})], O.prototype, "sortable", null), t([n()], O.prototype, "title", null), t([n({
  readOnly: !0
})], O.prototype, "publishing", null), t([n({
  readOnly: !0
})], O.prototype, "updating", null), t([n()], O.prototype, "view", void 0), t([n()], O.prototype, "visible", null), t([n({
  readOnly: !0
})], O.prototype, "visibleAtCurrentScale", null), t([n({
  readOnly: !0
})], O.prototype, "visibleAtCurrentTimeExtent", null), t([n({
  readOnly: !0
})], O.prototype, "visibilityMode", null), O = g = t([h("esri.widgets.LayerList.ListItem")], O);
const I = O;
export { I as default };